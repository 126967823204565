import React from 'react';
import { Helmet } from 'react-helmet-async';

function App() {
  return (
    <>
      <Helmet>
        <title>CapCut MOD APK - Download Premium Video Editor (No Watermark)</title>
        <meta name="description" content="Download CapCut MOD APK with premium features unlocked. Get access to professional video editing tools, no watermark, 4K export, and unlimited templates. Free download available." />
        <link rel="canonical" href="https://capcutapk.download/" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-blue-50/20 font-mulish">
        {/* Enhanced Header */}
        <header className="sticky top-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-100/50">
          <div className="section-container py-4">
            <div className="header-container">
              <div className="header-brand">
                <div className="w-10 h-10 rounded-xl bg-white/10 flex items-center justify-center">
                  <img 
                    src="capcut-icon.png"
                    alt="CapCut"
                    className="w-7 h-7"
                  />
                </div>
                <div>
                  <h1 className="text-2xl brand-gradient">
                    CapCut <span className="text-accent-light font-bold">PRO</span>
                  </h1>
                  <p className="text-xs text-blue-200/80 font-medium">Premium Features Unlocked</p>
                </div>
              </div>
              
              <div className="flex items-center gap-4">
                <div className="hidden md:flex items-center gap-3">
                  <span className="header-pill">v29.3.0</span>
                  <span className="header-pill">Android 5.0+</span>
                </div>
                <button className="premium-button px-6 py-2 rounded-lg flex items-center gap-2">
                  <span>Download</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="py-12">
          <div className="section-container">
            <div className="space-y-8">
              {/* App Info */}
              <div className="premium-container p-8">
                <div className="flex flex-col md:flex-row gap-8">
                  <div className="w-full md:w-64">
                    <img 
                      src="capcut-icon.png"
                      alt="CapCut Icon" 
                      className="w-32 h-32 mx-auto rounded-2xl shadow-lg"
                    />
                    <div className="mt-6 text-center">
                      <button className="w-full bg-primary hover:bg-primary-dark text-white font-bold py-3 px-6 rounded-lg transition-colors mb-3">
                        Download APK
                      </button>
                      <p className="text-sm text-gray-600">Version 1.0.0 (Latest)</p>
                      <p className="text-sm text-gray-600">Size: 15 MB</p>
                    </div>
                  </div>

                  <div className="flex-1">
                    <h1 className="heading-xl bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent mb-6">
                      CapCut Mod Apk 
                      <span className="text-lg font-bold text-primary ml-2 px-2 py-1 bg-primary/5 rounded-full">PRO</span>
                    </h1>
                    <div className="flex gap-6 mb-6">
                      <div className="text-center">
                        <span className="block text-3xl font-bold text-primary">1M+</span>
                        <span className="text-sm font-medium text-gray-600 uppercase tracking-wide">Downloads</span>
                      </div>
                      <div className="text-center">
                        <span className="block text-3xl font-bold text-primary">4.8★</span>
                        <span className="text-sm font-medium text-gray-600 uppercase tracking-wide">Rating</span>
                      </div>
                    </div>
                    <div className="prose max-w-none">
                      <h2 className="text-lg font-semibold mb-2">About CapCut</h2>
                      <p className="text-gray-600 mb-4">
                        CapCut is a powerful video editing app that offers professional features for free. Create amazing videos with effects, filters, music, and more.
                      </p>
                      <div className="bg-blue-50 p-4 rounded-lg mb-4">
                        <h3 className="font-semibold text-primary mb-2">What's New</h3>
                        <ul className="list-disc list-inside text-gray-600">
                          <li>New trendy effects and filters</li>
                          <li>Performance improvements</li>
                          <li>Bug fixes and stability enhancements</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Features */}
              <section className="premium-container p-8">
                <div className="text-center mb-12">
                  <h2 className="heading-lg mb-4">
                    Key Features of CapCut MOD APK
                  </h2>
                  <div className="h-1 w-20 bg-primary mx-auto rounded-full mb-4"></div>
                  <p className="body-lg max-w-2xl mx-auto">
                    Unlock the full potential of professional video editing with our enhanced features
                  </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  {enhancedFeatures.map((feature, index) => (
                    <div key={index} className="feature-card">
                      <div className="flex items-start gap-4">
                        <div className="text-3xl bg-blue-50 p-3 rounded-lg border-2 border-blue-100">{feature.icon}</div>
                        <div className="flex-1">
                          <h3 className="text-xl font-bold mb-2 text-gray-800 flex items-center gap-2">
                            {feature.title}
                            <span className="text-xs font-semibold bg-primary/10 text-primary px-2 py-1 rounded-full">PRO</span>
                          </h3>
                          <p className="text-gray-600 mb-4 font-medium">{feature.description}</p>
                          <ul className="list-none space-y-2">
                            {feature.subFeatures.map((sub, idx) => (
                              <li key={idx} className="flex items-center gap-2 text-sm text-gray-600">
                                <span className="text-primary">✓</span>
                                {sub}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              {/* APK Info */}
              <section className="premium-container p-8">
                <h2 className="text-3xl font-extrabold text-gray-800 mb-2">
                  Technical Specifications
                  <div className="h-1 w-20 bg-primary mt-2 rounded-full"></div>
                </h2>
                <p className="text-lg text-gray-600 mb-8 font-medium">Detailed information about CapCut MOD APK</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {apkInfo.map((category, index) => (
                    <div key={index} className="glass-effect rounded-xl p-6">
                      <h3 className="font-semibold text-primary mb-4">{category.title}</h3>
                      <ul className="space-y-3">
                        {category.items.map((item, idx) => (
                          <li key={idx} className="flex justify-between text-sm">
                            <span className="text-gray-600">{item.label}</span>
                            <span className="font-medium text-gray-800">{item.value}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </section>

              <section className="premium-container p-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">About CapCut MOD APK</h2>
                <div className="space-y-6 text-gray-600">
                  <p>CapCut MOD APK is an enhanced version of the popular video editing application that offers premium features without any cost. This modified version provides users with unlimited access to all premium features, removing watermarks and unlocking exclusive content.</p>
                  
                  <p>With over 1 billion downloads worldwide, CapCut has become the go-to video editor for content creators, social media influencers, and casual users alike. The MOD version takes this experience to the next level by unlocking premium features that are typically behind a paywall.</p>

                  <div className="bg-blue-50 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold text-primary mb-4">Why Choose CapCut MOD APK?</h3>
                    <p className="mb-4">The modified version offers several advantages over the official app:</p>
                    <ul className="list-disc list-inside space-y-2">
                      <li>Access to all premium effects and transitions without subscription</li>
                      <li>No watermark on exported videos</li>
                      <li>Enhanced export quality up to 4K resolution</li>
                      <li>Unlimited cloud storage for your projects</li>
                      <li>Ad-free experience for uninterrupted editing</li>
                      <li>All premium templates unlocked</li>
                    </ul>
                  </div>

                  <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Advanced Editing Features</h3>
                  <p>CapCut MOD APK comes packed with professional-grade editing tools that cater to both beginners and advanced users:</p>
                  
                  <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
                    <li>Advanced chroma key (green screen) effects</li>
                    <li>Professional color grading tools</li>
                    <li>Motion tracking capabilities</li>
                    <li>Advanced audio editing features</li>
                    <li>Multiple aspect ratio support</li>
                    <li>Advanced text animations and effects</li>
                  </ul>

                  <h3 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Regular Updates</h3>
                  <p>Our MOD APK is regularly updated to match the latest official version features while maintaining all premium unlocks. This ensures you always have access to the newest features and security improvements.</p>
                </div>
              </section>

              <section className="premium-container p-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">CapCut MOD vs Official Version</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-50">
                      <tr>
                        {comparisonHeaders.map((header, index) => (
                          <th key={index} className="py-3 px-6 text-left text-sm font-semibold text-gray-700 border">
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {comparisonData.map((row, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex} className="py-3 px-6 text-sm text-gray-600 border">
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>

              <section className="premium-container p-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">How to Install CapCut MOD APK</h2>
                <div className="space-y-4">
                  {installSteps.map((step, index) => (
                    <div key={index} className="flex gap-4">
                      <div className="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center flex-shrink-0">
                        {index + 1}
                      </div>
                      <p className="text-gray-600">{step}</p>
                    </div>
                  ))}
                </div>
              </section>

              {/* FAQ section with enhanced styling */}
              <section className="premium-container p-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">Frequently Asked Questions</h2>
                <div className="space-y-4">
                  {enhancedFaqs.map((faq, index) => (
                    <div key={index} 
                         className="glass-effect rounded-xl p-6 hover:shadow-premium transition-all duration-300">
                      <h3 className="text-lg font-semibold text-gray-800 mb-2">{faq.question}</h3>
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </main>

        {/* Enhanced Footer */}
        <footer className="bg-gray-900/90 backdrop-blur-md mt-12">
          <div className="section-container py-8">
            <div className="content-container text-center">
              <p className="text-white/80 font-medium">
                © 2024 CapCut by ByteDance. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

const modFeatures = [
  "All Premium Effects Unlocked",
  "No Watermark Export",
  "4K Export Resolution",
  "All Premium Templates Available",
  "Unlimited Cloud Storage",
  "Ad-Free Experience"
];

const comparisonHeaders = ["Features", "Official Version", "MOD Version"];
const comparisonData = [
  { feature: "Premium Effects", official: "Limited", mod: "All Unlocked" },
  { feature: "Watermark", official: "Yes", mod: "No" },
  { feature: "Export Quality", official: "Up to 1080p", mod: "Up to 4K" },
  { feature: "Cloud Storage", official: "Limited", mod: "Unlimited" },
  { feature: "Ads", official: "Yes", mod: "No" },
  { feature: "Premium Templates", official: "Paid", mod: "Free" }
];

const installSteps = [
  "First, uninstall any existing version of CapCut from your device.",
  "Enable 'Install from Unknown Sources' in your device settings.",
  "Download the CapCut MOD APK from the button above.",
  "Open the downloaded APK file and tap 'Install'.",
  "Wait for the installation to complete.",
  "Open CapCut and enjoy all premium features!"
];

const faqs = [
  {
    question: "Is CapCut MOD APK safe to use?",
    answer: "Our MOD APK is scanned for malware and verified to be safe. However, always download from trusted sources and keep your device protected."
  },
  {
    question: "Will I lose my projects after installing the MOD version?",
    answer: "No, your projects will remain intact if you backup your data before uninstalling the official version."
  },
  {
    question: "Do I need root access to install CapCut MOD APK?",
    answer: "No, root access is not required to install and use CapCut MOD APK."
  },
  {
    question: "Can I update the MOD APK automatically?",
    answer: "No, automatic updates are disabled. Check back here for the latest MOD version."
  }
];

const enhancedFaqs = [
  {
    question: "Is CapCut MOD APK safe to use?",
    answer: "Our MOD APK is scanned for malware and verified to be safe. However, always download from trusted sources and keep your device protected. We regularly update our MOD version to ensure compatibility and security."
  },
  {
    question: "Will I lose my projects after installing the MOD version?",
    answer: "No, your projects will remain intact if you backup your data before uninstalling the official version. We recommend saving your projects to the cloud or local storage before installation."
  },
  {
    question: "Do I need root access to install CapCut MOD APK?",
    answer: "No, root access is not required to install and use CapCut MOD APK. It works on any Android device running Android 5.0 or higher."
  },
  {
    question: "Can I update the MOD APK automatically?",
    answer: "No, automatic updates are disabled to maintain the MOD features. Check back here for the latest MOD version. We regularly update our MOD APK to match the official app's latest features."
  },
  {
    question: "Will my videos have a watermark?",
    answer: "No, the MOD version removes all watermarks from exported videos. You can export clean, professional-looking videos without any CapCut branding."
  },
  {
    question: "Can I use CapCut MOD APK for commercial purposes?",
    answer: "Yes, you can use CapCut MOD APK for commercial projects. However, please be aware of your local regulations regarding modified applications."
  },
  {
    question: "How does cloud storage work in the MOD version?",
    answer: "The MOD version provides unlimited cloud storage for your projects, allowing you to save and access your work from any device. Simply log in with your account to sync your projects."
  },
  {
    question: "What happens if I encounter bugs or issues?",
    answer: "If you experience any issues, try clearing the app cache, ensuring you have enough storage space, or reinstalling the MOD APK. If problems persist, check our website for an updated version."
  },
  {
    question: "Can I transfer my premium purchases from the official app?",
    answer: "The MOD version already includes all premium features unlocked, so there's no need to transfer purchases. You can enjoy all premium features without any additional cost."
  },
  {
    question: "Is the MOD version available for iOS?",
    answer: "No, the MOD version is currently only available for Android devices due to iOS system restrictions. iOS users should use the official App Store version."
  }
];

const apkInfo = [
  {
    title: "Technical Details",
    items: [
      { label: "App Name", value: "CapCut MOD APK" },
      { label: "Latest Version", value: "29.3.0" },
      { label: "File Size", value: "98.5 MB" },
      { label: "MOD Version", value: "Premium Unlocked" },
      { label: "Android Required", value: "5.0+" },
      { label: "Last Updated", value: "January 2024" }
    ]
  },
  {
    title: "Developer Information",
    items: [
      { label: "Original Developer", value: "ByteDance Pte. Ltd" },
      { label: "Category", value: "Video Editor" },
      { label: "Google Play ID", value: "com.lemon.lvoverseas" },
      { label: "Package Name", value: "com.lemon.lvoverseas.mod" },
      { label: "Content Rating", value: "3+" },
      { label: "Installation", value: "100M+" }
    ]
  },
  {
    title: "MOD Features",
    items: [
      { label: "Premium Status", value: "Unlocked" },
      { label: "Ads Status", value: "Removed" },
      { label: "Watermark", value: "Removed" },
      { label: "Export Quality", value: "4K Support" },
      { label: "Premium Effects", value: "Unlocked" },
      { label: "Cloud Storage", value: "Unlimited" }
    ]
  },
  {
    title: "Support & Compatibility",
    items: [
      { label: "Processor", value: "All ARM" },
      { label: "Languages", value: "Multi-Language" },
      { label: "Root Required", value: "No" },
      { label: "Internet Required", value: "Yes" },
      { label: "Offline Support", value: "Partial" },
      { label: "App Architecture", value: "Universal" }
    ]
  },
  {
    title: "File Information",
    items: [
      { label: "Original APK", value: "85.2 MB" },
      { label: "MOD APK", value: "98.5 MB" },
      { label: "SHA-256", value: "Verified" },
      { label: "Signature", value: "Valid" },
      { label: "CPU Architectures", value: "arm64-v8a" },
      { label: "Anti-Ban", value: "Protected" }
    ]
  },
  {
    title: "Update Information",
    items: [
      { label: "Update Type", value: "Manual" },
      { label: "Update Frequency", value: "Monthly" },
      { label: "Latest Changes", value: "Bug Fixes" },
      { label: "Base Version", value: "Original 29.3.0" },
      { label: "Release Date", value: "Jan 15, 2024" },
      { label: "Update Priority", value: "Recommended" }
    ]
  }
];

const enhancedFeatures = [
  {
    icon: "✨",
    title: "Professional Effects Suite",
    description: "Transform your videos with cinema-grade effects and seamless transitions.",
    subFeatures: [
      "**NEW** 500+ Premium cinematic effects with customizable parameters",
      "**PRO** Advanced green screen technology with AI edge detection",
      "Industry-standard color grading with LUT support and custom presets",
      "Real-time motion tracking with precision control",
      "Dynamic visual effects with keyframe animation support",
      "Seamless transitions with customizable duration and blending modes",
      "Professional blur effects including tilt-shift and gaussian blur",
      "Advanced overlay effects with blend mode controls"
    ]
  },
  {
    icon: "🎵",
    title: "Complete Audio Production Studio",
    description: "Experience studio-quality audio editing with advanced mixing capabilities and AI-enhanced sound processing.",
    subFeatures: [
      "Professional audio mixing console with multi-track support",
      "AI-powered noise reduction and audio enhancement",
      "Built-in equalizer with custom frequency control",
      "Royalty-free music library with 100,000+ tracks",
      "Advanced voice-over recording with real-time effects",
      "Automatic beat detection for perfect video sync",
      "Sound effect library with categorized collections",
      "Custom audio extraction and mixing tools"
    ]
  },
  {
    icon: "🎯",
    title: "Advanced AI-Powered Tools",
    description: "Leverage cutting-edge artificial intelligence to automate complex editing tasks and enhance video quality.",
    subFeatures: [
      "Smart scene detection with automated clip organization",
      "AI-powered background removal with hair strand precision",
      "Intelligent color correction based on scene analysis",
      "Automated subtitle generation in multiple languages",
      "Face detection and beauty enhancement tools",
      "Smart composition suggestions for better framing",
      "Motion tracking with sub-pixel accuracy",
      "AI-enhanced video stabilization"
    ]
  },
  {
    icon: "🎬",
    title: "Professional Timeline & Editing Tools",
    description: "Edit with precision using professional-grade timeline controls and advanced editing features.",
    subFeatures: [
      "Multi-layer video editing with unlimited tracks",
      "Frame-accurate trimming and splitting tools",
      "Advanced keyframe animation system",
      "Nested sequence support for complex projects",
      "Custom project templates and presets",
      "Time remapping with speed ramping",
      "Advanced masking and rotoscoping tools",
      "Project organization with bins and markers"
    ]
  },
  {
    icon: "🎨",
    title: "Creative Assets & Templates",
    description: "Access a vast library of professional templates and creative assets for any type of content.",
    subFeatures: [
      "1000+ Premium video templates for various occasions",
      "Professional intro and outro templates",
      "Social media-optimized templates for all platforms",
      "Custom animated stickers and emojis",
      "Vector-based shape library with animation presets",
      "Business presentation templates with infographics",
      "Custom template creation and sharing",
      "Regular template updates following trending styles"
    ]
  },
  {
    icon: "📱",
    title: "Cross-Platform Workflow",
    description: "Seamlessly edit and access your projects across all devices with advanced cloud integration.",
    subFeatures: [
      "Real-time project syncing across devices",
      "Unlimited cloud storage for project files",
      "Offline editing with automatic sync",
      "Collaborative editing features for team projects",
      "Version control and project history",
      "Automatic project backup system",
      "Cross-platform asset management",
      "Smart resource optimization for mobile devices"
    ]
  },
  {
    icon: "🔄",
    title: "Professional Export Engine",
    description: "Export your videos in professional quality with advanced encoding options and format support.",
    subFeatures: [
      "4K HDR video export support",
      "Custom bitrate and codec selection",
      "Multiple format export (MP4, MOV, AVI)",
      "Batch export capabilities",
      "Custom aspect ratio presets",
      "Platform-specific export settings",
      "Lossless quality options",
      "Background rendering support"
    ]
  },
  {
    icon: "🎭",
    title: "Advanced Text & Graphics",
    description: "Create stunning text animations and graphic overlays with professional design tools.",
    subFeatures: [
      "Premium font collection with cloud sync",
      "Advanced text animation engine",
      "3D text effects with realistic lighting",
      "Custom path animation for text",
      "Shape animation with keyframe control",
      "Vector graphic import and editing",
      "Layer styles and effects for text",
      "Kinetic typography templates"
    ]
  }
];

export default App;